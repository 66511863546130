<template>
    <v-container fluid>
        
        <v-row>
            <v-col cols="6">
                <h3>My Charger</h3>
            </v-col>
            <v-col cols="6" class="text-right">
                <v-btn 
                    color="primary"
                    @click="addCharger"
                >
                    <v-icon left>mdi-plus-circle</v-icon>
                    Add Charger
                </v-btn>
            </v-col>
        </v-row>


        <template>
          <charging-station-list />
        </template>
        
    </v-container>
  </template>
  
  <script>
  import Vue from "vue";
  import userManager from "@/services/userManager";
  import moment from "moment";
  import ChargingStationList from './ChargingStationListM.vue';
  export default Vue.extend({
    components: { ChargingStationList },
    data() {
      return {
        userManager,
        self: this,
        moment,
        initializeLoading: false,
        initializeError: null
      };
    },
    methods: {
      async initialize() {
      },
      async addCharger() {
        this.$router.push(`/app/my_charger/login_charger_m`);
      },
    },
    async created() {
      await this.initialize();
    },
  });
  </script>
  
  <style scoped>
  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(102, 102, 102, 1);
  }
  
  .label-value {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: right;
    color: rgba(102, 102, 102, 1);
  }
  
  .label-strong {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(74, 70, 67, 1);
  }
  
  .label-value-normal {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: right;
    color: rgba(102, 102, 102, 1);
  }
  
  .label-value-strong {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: right;
    color: rgba(51, 51, 51, 1);
  }
  </style>
