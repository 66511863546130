<template>
  <div v-if="chargingStations">
    <div v-for="station in chargingStations" :key="getKeyState(station)">
      <br>
      <v-card class="card">
        <v-card-title class="d-flex justify-space-between">
          <div class="title">
            {{ station.chargerModelName }}-{{ station.serialNumber }}-{{ station.qrCode }}
          </div>
          <div class="action" @click="goHistoryCharging(station)">
            <span class="charging-history">Charging history<v-icon>mdi-chevron-right</v-icon></span>
          </div>
        </v-card-title>
        <v-card-text>
          <span class="status-label mr-5">Status</span>
          <v-chip :color="getStatusColor(station.status)">
            {{ station.status }}
          </v-chip>
        </v-card-text>

        <v-card-actions class="justify-end" v-if="buildBtnName(station.status) === null">
          <v-btn class="button-charge" block @click="goConfigCharger(station)">
            <div width="17">
              <v-img :src="midSetting" width="17px" />
            </div>
            Configure
          </v-btn>
        </v-card-actions>
        <v-card-actions class="justify-end" v-if="buildBtnName(station.status) !== null">
          <v-row justify="center" align="center" cols="12" sm="6" md="6">
            <v-col cols="6">
              <v-btn @click="clickDetail(station)" class="button-charge button-charge-detail" color="primary" block>
                <v-icon>mdi-ev-station</v-icon>{{ buildBtnName(station.status) }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="button-charge" block @click="goConfigCharger(station)">
                <div width="17">
                  <v-img :src="midSetting" width="17px" />
                </div>
                Configure
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    <v-col cols="auto">
      <v-dialog transition="dialog-top-transition" width="100%" v-model="dialog.isActive">

        <template>
          <v-card v-if="dialog">
            <v-toolbar color="primary" dark>
              <v-toolbar-title class="flex-grow-1">{{ dialog.title }}</v-toolbar-title>
              <v-btn icon @click="closeNotification">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="text-center">
              <img width="158" height="189" class="center-svg" :src="dialog.imgSvg" alt="SVG" />
              <br>
              <div class="caption-false">
                <div class="caption-size-false">Please wait while the charger is being connected ...</div>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import midSetting from '@/assets/images/svg/mid-setting.svg';
import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";
import { ChargingStation } from "@/utils/chargingStation";
import waltChargerConnect from "@/assets/images/svg/waltChargerConnect.svg";

export default Vue.extend({
  data() {
    return {
      chargingStations: null,
      midSetting,
      dialog: {
        isActive: false,
        imgSvg: waltChargerConnect,
        title: 'Notification'
      }
    };
  },
  methods: {
    goConfigCharger(station) {
      this.$router.push(`/app/my_charger/charging_config_m/${station.id}/${station.connectorId}`);
    },
    goHistoryCharging(station) {
      this.$router.push(`/app/my_charger/charging_session_history_m/${station.id}/${station.connectorId}`);
    },
    async closeNotification() {
      await this.initialize();
      this.dialog.isActive = false;
    },
    async clickDetail(station) {
      const status = station.status
      if (status === 'available' || status === 'preparing' || status === 'finishing') {
        this.startCharging(station)
      } else if (status === 'charging' || status === 'suspendedEV' || status === 'suspendedEVSE') {
        this.$router.push(`/app/my_charger/charging_stations_m/${station.id}/${station.connectorId}`);
      }
    },
    async startCharging(station) {
      const idStation = station.id;
      const connectorIdStation = station.connectorId;
      const result = await coreApiClient.call("chargingStations", "publicStartCharging", {
        id: idStation,
        connectorId: connectorIdStation,
      }, null, false, this.getKeyState(station));
      if (result && result === true) {
        this.dialog.isActive = true
      }
    },
    getStatusColor(status) {
      if (status === 'available' || status === 'reserved') {
        return '#26CC37'; // Green color
      } else if (status === 'charging') {
        return '#FFA500'; // Orange color
      } else if (status === 'preparing' || status === 'finishing') {
        return '#275ADC'; // Blue color
      } else if (status === 'suspendedEV' || status === 'suspendedEVSE') {
        return '#FFCC00'; // Yellow color
      } else {
        return '#FF0000'; // Red color
      }
    },
    getKeyState(state) {
      return state.id + ":" + state.connectorId
    },
    buildBtnName(status) {
      if (status === 'available' || status === 'preparing' || status === 'finishing') {
        return 'Start Charging';
      } else if (status === 'charging' || status === 'suspendedEV' || status === 'suspendedEVSE') {
        return 'Charging Details';
      }
      return null;
    },
    async initialize() {
      const objCharger = stateManager.get("objCharger");

      for (const key of Object.keys(objCharger)) {
        if (objCharger[key]?.status === 'loggedOut') {
          continue
        }

        const keyChargingStations = this.getKeyState(objCharger[key])
        const result = await coreApiClient.callRaw("chargingStations", "publicGetConnectorInfo", {
          id: objCharger[key].id,
          connectorId: objCharger[key].connectorId,
        }, null, false, keyChargingStations);
        if (result && result.errorCode === 'NoError') {
          objCharger[key].status = ChargingStation.getStatus(result.status)
        }
      }
      stateManager.set("objCharger", objCharger)

      this.chargingStations = objCharger
    },
  },
  created() {
    this.initialize();
  }
});
</script>
<style scoped>
.charging-history {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #6B7280;
  display: flex;
  justify-content: center;
  align-items: center
}

.button-charge {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.title {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

@media only screen and (max-width: 397px) {
  .button-charge-detail {
    max-width: 162px;
  }
}
</style>

